import React from "react";
import dummy from "../../assets/images/dummy.jpeg";
import "./Course.css";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";
import { FaStarHalf, FaStar } from "react-icons/fa";
import { LiaCertificateSolid } from "react-icons/lia";
import { AiFillStar, AiOutlineStar, AiFillLock } from "react-icons/ai";
import { BsPlayCircle, BsPlayFill, BsLock } from "react-icons/bs";
import { TiTick } from "react-icons/ti";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import certificate from "../../assets/images/kareersity-certificate.png";
import imgdummy from "../../assets/images/imgdummy.png";
import ProgressBar from "react-bootstrap/ProgressBar";
import c1 from "../../assets/images/course.png";
import Accordion from "react-bootstrap/Accordion";
import { HiChevronDoubleRight } from "react-icons/hi";
import {
  activeRatingsandReviews,
  addToCart,
  buyNow,
  listOfRelatedCourses,
  viewActiveCourses,
} from "../../redux/courses";
import { toast } from "react-toastify";
import { loader, isLogin, cartCount } from "../../redux/common";
import Modal from "react-bootstrap/Modal";
import { userAcademicDetail } from "../../redux/profile";
const Courselist = () => {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const courseId = useParams();
  let [refresher, setRefresher] = useState(true);
  const [courseList, setCourseList] = useState([]);
  const [relatedCourseList, setRelatedCourseList] = useState([]);
  const [billingDetails, setBillingDetails] = useState([]);
  const [sessionData, setSessionData] = useState([]);
  const [lessonData, setLessonData] = useState("");
  const [showFullContent, setShowFullContent] = useState(false);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [ratingsData, setRatingsData] = useState([]);
  const cartCountval = useSelector((state) => state.apiReducer.cartCount);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showAllReviews, setShowAllReviews] = useState(false);
  const [flag, setFlag] = useState(true);
  const handleShowMoreToggle = () => {
    setShowAllReviews(!showAllReviews);
  };
  const [academicData, setAcademicData] = useState({});

  const handleShowModal = (lesson) => {
    setSelectedLesson(lesson);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedLesson(null);
    setShowModal(false);
  };
  useEffect(() => {
    let data = courseId.id;
    dispatch(loader(true));
    dispatch(
      viewActiveCourses(data, (resp) => {
        if (resp.status) {
          console.log(resp.data);
          setCourseList(resp.data);
          setSessionData(resp.data[0].session);
          setLessonData(resp.data[0].session[0].lessons[0]);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
        }
      })
    );

    dispatch(
      userAcademicDetail((resp) => {
        dispatch(loader(true));
        if (resp.status) {
          console.log(resp.data);
          setAcademicData(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          //toast.error(resp.message);
        }
      })
    );

    dispatch(
      listOfRelatedCourses(data, (resp) => {
        if (resp.status) {
          setRelatedCourseList(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
        }
      })
    );
    let reqData = {};
    reqData["courseId"] = courseId.id;

    dispatch(
      activeRatingsandReviews(reqData, (resp) => {
        if (resp.status) {
          setRatingsData(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  }, [refresher, flag]);

  const addCart = (id, index) => {
    let data = {};
    data["courseId"] = id;

    dispatch(
      addToCart(data, (response) => {
        if (response.status) {
          toast.success(response.message);
          setRefresher(!refresher);
          navigate("/cart");
          dispatch(cartCount(!cartCountval));
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(response.message);
        }
      })
    );
  };

  const buyNowfn = (id) => {
    let data = {};
    data["courseId"] = id;

    dispatch(
      addToCart(data, (response) => {
        if (response.status) {
          toast.success(response.message);
          setRefresher(!refresher);
          navigate("/checkout");
          dispatch(loader(false));
        } else {
          toast.error(response.message);
          dispatch(loader(false));
        }
      })
    );
  };
  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };
  function convertTimestampToDays(timestamp) {
    const currentTime = Math.floor(Date.now() / 1000); // Get current time in seconds
    const remainingTime = timestamp - currentTime; // Calculate the time difference
    const days = Math.ceil(remainingTime / (60 * 60 * 24)); // Convert seconds to days

    return days;
  }

  function convertExpiryToDate(expiryTimestamp) {
    const expiryDate = new Date(expiryTimestamp);
    return expiryDate;
  }

  function isDiscountValid(discountedPriceExpiryTimestamp) {
    const currentTime = Math.floor(Date.now() / 1000);
    return currentTime < discountedPriceExpiryTimestamp;
  }

  function isCourseFree(item) {
    const currentTime = Math.floor(Date.now() / 1000);
    const userCreationDate = localStorage.getItem("UserCreatedDate");
    const expiryDate = convertExpiryToDate(userCreationDate);
    const collegeId = academicData?.collegeId;
    const thirtyDaysInSeconds = 30 * 24 * 60 * 60; // 30 days in seconds

    if (item.freeForEveryone) {
      return true; // Course is free for everyone
    } else if (
      item.freeForEnInLast30Days &&
      currentTime - expiryDate.getTime() <= thirtyDaysInSeconds
    ) {
      return true; // Course is free for users who registered in the last 30 days
    } else if (
      item.freeForbasedOnColleges &&
      item.freeColleges != null &&
      item.freeColleges.includes(collegeId)
    ) {
      return true; // Course is free for users based on their college
    } else {
      return false; // Course is not free and discount has expired or not applicable
    }
  }
  const calculatePercentage = (starRatings, totalRatings) => {
    if (totalRatings > 0) {
      const percentage = (starRatings / totalRatings) * 100;
      return Math.round(percentage);
    }
    return 0;
  };

  return (
    <div className="CouREseLIstPA">
      {courseList.map((item, index) => {
        const fullStars = Math.floor(item.avgRating); // Number of full stars
        const hasHalfStar = item.avgRating % 1 !== 0;
        return (
          <div>
            <div className="PageHEaders COUrseLi">
              <div className="container">
                <div className="row">
                  <div className="col-lg-7 col-md-12">
                    <div className="PageTi  pt-5 pb-5">
                      <p>
                        <Link to="/">Home</Link>{" "}
                        <i>
                          <FaAngleRight />
                        </i>
                        <Link to="/course">Course</Link>
                        <i>
                          <FaAngleRight />
                        </i>
                        <Link to="/course">{item.category}</Link>
                        <i>
                          <FaAngleRight />
                        </i>{" "}
                        <Link
                          to={`/course-detail/${item._id}/${item.title.replace(
                            / /g,
                            "_"
                          )}`}
                        >
                          {item.title}
                        </Link>
                      </p>

                      <h2>{item.title}</h2>
                      <p>{item.shortDescription}</p>
                      <h6 className="d-flex CL info">
                        <p>By {item.educators.name}</p>
                        <p>{item.level}</p>
                      </h6>
                      <h6 className="d-flex CL align-items-center">
                        <p className="d-flex align-items-center">
                          <i>
                            <LiaCertificateSolid />
                          </i>
                          CERTIFIED COURSE
                        </p>
                        <p className="d-flex star">
                          {item.totalRatings >= 0 ? (
                            <>
                              {[...Array(fullStars)].map((star, starIndex) => (
                                <AiFillStar
                                  key={starIndex}
                                  style={{ color: "#ffc107" }}
                                />
                              ))}
                              {hasHalfStar && (
                                <FaStarHalf style={{ color: "#ffc107" }} />
                              )}
                              <div className="ratingscl">
                                {item.avgRating} ({item.totalRatings} Rating)
                              </div>
                            </>
                          ) : null}{" "}
                        </p>
                        <p>{item.totalStudents} Students</p>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="CorLiSt pt-5 pb-5">
              <div className="container">
                <div className="row">
                  <div className="col-lg-7 col-md-12 ">
                    <div className="CoRTabS">
                      <div className="backgrounclr lgtgrn">
                        <ul className="CourdetCont">
                          <li>
                            <a href="#first">Overview</a>
                          </li>
                          <li>
                            <a href="#second">Instructor</a>
                          </li>
                          <li>
                            <a href="#third">Course is for</a>
                          </li>
                          <li>
                            <a href="#fourth">Course Includes</a>
                          </li>
                          <li>
                            <a href="#five">Ratings & Reviews</a>
                          </li>
                          <li>
                            <a href="#six">Pricing </a>
                          </li>
                        </ul>
                      </div>

                      <div id="first">
                        <div className="OveRView pt-4 pb-4">
                          <h4>Course description</h4>

                          <p
                            dangerouslySetInnerHTML={{
                              __html: showFullContent
                                ? item.description
                                : item.description.slice(0, 100), // Display the first 100 characters
                            }}
                          ></p>
                          {item.description.length > 200 && (
                            <Link to="#" onClick={toggleContent}>
                              {showFullContent ? "Show Less" : "Show More"}
                            </Link>
                          )}
                        </div>
                        <hr />
                        <div className="CLwhat">
                          <h4>What you'll learn</h4>
                          <div className="row">
                            <div className="col-lg-12 col-md-12">
                              <ul className="LearNList d-flex gap-2 ">
                                <i style={{ color: "green" }}>
                                  <TiTick />
                                </i>
                                <li
                                  dangerouslySetInnerHTML={{
                                    __html: item.whatWillYouLearn,
                                  }}
                                ></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="second">
                        <div className="ValUBCeR pt-3">
                          <h4>Valuable certifications</h4>
                          <div className="row">
                            <div className="col-lg-5 col-md-4 col-sm-12 pt-2">
                              <div className="ValueImG ">
                                <img src={certificate} className="img-fluid" />
                              </div>
                            </div>
                            <div className="col-lg-7 col-md-8 col-sm-12 pt-2">
                              <div className="ValuCoN">
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: item.certifications,
                                  }}
                                ></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="third">
                        <div className="EDClist pt-4">
                          <h4>Educator</h4>
                          <div className="EdConT mb-4">
                            <div className="row">
                              <div className="col-lg-3 col-md-12">
                                <div className="EDImG">
                                  <img
                                    src={item.educators.photoUrl}
                                    className="img-fluid"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-9 col-md-12">
                                <div className="EdConT">
                                  <h4>{item.educators.name}</h4>
                                  <h6>{item.educators.designation}</h6>
                                  <div className="ECLD">
                                    <p>
                                      <i>
                                        <AiFillStar />
                                      </i>{" "}
                                      {item.avgRating} ( {item.totalRatings}{" "}
                                      Rating ) {item.educators.totalStudents}{" "}
                                      Students{" "}
                                    </p>
                                    <p>{item.totalReviews} Review</p>
                                    <p>{item.educators.countCourses} Courses</p>
                                  </div>

                                  <p>{item.educators.description}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="fourth">
                        <div className="OveRView pt-4 pb-4">
                          <h4>Who this course is for</h4>

                          <p
                            dangerouslySetInnerHTML={{
                              __html: showFullContent
                                ? item.whoThisCourseIsFor
                                : item.whoThisCourseIsFor.slice(0, 100),
                            }}
                          ></p>
                          {item.whoThisCourseIsFor.length > 200 && (
                            <Link to="#" onClick={toggleContent}>
                              {showFullContent ? "Show Less" : "Show More"}
                            </Link>
                          )}
                        </div>
                      </div>
                      <div id="five">
                        <div className="ValuCoN courseLI">
                          <h4>Course Includes</h4>

                          <div className="row">
                            <div className="col-lg-6 col-md-12 col-sm-12">
                              <ul className="LearNList">
                                {item?.courseIncludes
                                  ?.slice(
                                    0,
                                    Math.ceil(item.courseIncludes.length / 2)
                                  )
                                  .map((items, i) => (
                                    <li key={i}>
                                      <i>
                                        <TiTick />
                                      </i>
                                      {items}
                                    </li>
                                  ))}
                              </ul>
                            </div>

                            <div className="col-lg-6 col-md-12 col-sm-12">
                              <ul className="LearNList">
                                {item?.courseIncludes
                                  ?.slice(
                                    Math.ceil(item.courseIncludes.length / 2)
                                  )
                                  .map((items, i) => (
                                    <li key={i}>
                                      <i>
                                        <TiTick />
                                      </i>
                                      {items}
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <hr />

                        <div className="CLrating ">
                          <h4>Ratings & Reviews</h4>
                          <div className="row">
                            <div className="col-lg-3 col-md-12">
                              <div className="GrnRati mt-3">
                                <h3> {item.avgRating} </h3>
                                <p className="staR d-flex">
                                  {item.totalRatings > 0 ? (
                                    <>
                                      {[...Array(fullStars)].map(
                                        (star, starIndex) => (
                                          <AiFillStar
                                            key={starIndex}
                                            style={{ color: "#ffc107" }}
                                          />
                                        )
                                      )}
                                      {hasHalfStar && (
                                        <FaStarHalf
                                          style={{ color: "#ffc107" }}
                                        />
                                      )}
                                    </>
                                  ) : null}
                                </p>
                                <h6>Course Rating</h6>
                              </div>
                            </div>

                            <div className="col-lg-9 pt-3">
                              <div className="row">
                                {Array.from({ length: 5 }, (_, index) => (
                                  <div key={index} className="col-lg-12">
                                    <div className="MAinFlip d-flex align-items-center">
                                      <div className="RatinGBAr">
                                        <p>
                                          {5 - index}{" "}
                                          <i>
                                            <AiFillStar />
                                          </i>
                                        </p>
                                      </div>
                                      <div className="ProgressBarContainer flex-grow-1">
                                        <ProgressBar
                                          now={calculatePercentage(
                                            item[
                                              `total${5 - index}StarRatings`
                                            ],
                                            item.totalRatings
                                          )}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />

                        <div className="CommCL">
                          <h4>
                            {ratingsData ? ratingsData.length : 0} reviews
                          </h4>
                          {ratingsData && ratingsData.length > 0 ? (
                            ratingsData
                              .slice(0, showAllReviews ? ratingsData.length : 4)
                              .map((item, index) => (
                                <div className="row pt-4" key={index}>
                                  <div className="col-2">
                                    <div className="CommImG">
                                      <img
                                        src={
                                          item.userProfile
                                            ? item.userProfile
                                            : dummy
                                        }
                                        className="img-fluid"
                                        style={{ width: "100%" }}
                                        alt={`Review by ${item.fullName}`}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-5">
                                    <div className="CommConT">
                                      <h4>{item.fullName}</h4>
                                      <p>{item.reviews}</p>
                                    </div>
                                  </div>
                                  <div className="col-5">
                                    <div className="StarFIVe">
                                      {item.rating > 0 ? (
                                        <>
                                          {[...Array(item.rating)].map(
                                            (star, starIndex) => (
                                              <FaStar
                                                key={starIndex}
                                                style={{ color: "#ffc107" }}
                                              />
                                            )
                                          )}
                                          {item.rating % 1 !== 0 && (
                                            <FaStarHalf
                                              style={{ color: "#ffc107" }}
                                            />
                                          )}
                                        </>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="CommConT"></div>
                                  </div>
                                  <hr />
                                </div>
                              ))
                          ) : (
                            <p>No reviews available.</p>
                          )}
                          {ratingsData && ratingsData.length > 4 && (
                            <div className="row">
                              <div className="col-12">
                                <Link to="#" onClick={handleShowMoreToggle}>
                                  {showAllReviews ? "Show Less" : "Show More"}
                                </Link>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div id="six">
                        <div className="OveRView pt-4 pb-4">
                          <h3>Pricing Structure</h3>

                          {item.pricingStructure.map((price, index) => (
                            <div key={index} className="pricing-item">
                              <p>
                                <strong style={{ fontSize: "1.2em" }}>
                                  {price.name}
                                </strong>
                              </p>
                              <p>
                                <span
                                  style={{
                                    textDecoration: "line-through",
                                    color: "#999",
                                    marginRight: "12px",
                                    fontSize: "1.1em",
                                  }}
                                >
                                  ₹{price.amount}
                                </span>
                                {price.hasDiscount && (
                                  <span
                                    style={{
                                      color: "#2ecc71",
                                      fontWeight: "bold",
                                      fontSize: "1.3em",
                                      display: "inline-block",
                                      padding: "2px 8px",
                                      borderRadius: "4px",
                                      backgroundColor: "#e8f8f1",
                                    }}
                                  >
                                    ₹{price.discountAmount}
                                  </span>
                                )}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-12 ">
                    <div className="entireCont">
                      <div className="RightCL">
                        <div className="CLrighTImg">
                          <img src={item.thumbnail} className="img-fluid" />
                        </div>

                        <div className="PlyIcon greencircle">
                          <i
                            className="ggrn"
                            onClick={() => {
                              handleShowModal(lessonData);
                            }}
                          >
                            <BsPlayFill />
                          </i>
                        </div>

                        <div className="Rl d-flex mt-4">
                          <div className="RightCLcont d-flex">
                            {isCourseFree(item) ? (
                              <h2>FREE</h2>
                            ) : (
                              <>
                                {item.discountedPrice > 0 ? (
                                  <>
                                    <h2>₹{item.discountedPrice}</h2>
                                    <h3>
                                      <s>₹{item.price}</s>
                                    </h3>
                                  </>
                                ) : (
                                  <>
                                    <h2>₹{item.regularPrice}</h2>
                                    <h3>
                                      <s>₹{item.price}</s>
                                    </h3>
                                  </>
                                )}
                              </>
                            )}
                          </div>

                          <div className="lEft HRS">
                            {item.discountedPriceExpiry ? (
                              <h4>
                                {convertTimestampToDays(
                                  item.discountedPriceExpiry
                                ) > 0
                                  ? `${convertTimestampToDays(
                                      item.discountedPriceExpiry
                                    )} days left!`
                                  : ""}
                              </h4>
                            ) : null}
                          </div>
                        </div>

                        <div className="row mt-4 mb-4 bUttNs">
                          <div className="col-lg-6 col-md-6 col-12">
                            <div className="GrnBtNN">
                              <button
                                onClick={() => {
                                  addCart(item._id, index);
                                  window.scrollTo(0, 0);
                                }}
                              >
                                Add to Cart
                              </button>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-12">
                            <div className="BdrstNN">
                              <button
                                onClick={() => {
                                  buyNowfn(item._id, index);
                                  window.scrollTo(0, 0);
                                }}
                              >
                                Buy Now
                              </button>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="d-flex mb-3">
                          <div className="ClRConteNt">
                            <h4>Course Content</h4>
                          </div>
                          <div className="Hrs">
                            <h5>{item.duration}</h5>
                          </div>
                        </div>
                        <hr />
                        {sessionData.map((session, sessionIndex) => (
                          <div key={sessionIndex} className="ACCclRight">
                            <Accordion defaultActiveKey={["0"]} alwaysOpen>
                              <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                  {sessionIndex + 1}.{session.title}
                                </Accordion.Header>
                                <Accordion.Body>
                                  {session.lessons.map(
                                    (lesson, lessonIndex) => (
                                      <div
                                        key={lessonIndex}
                                        className="AccContEnt d-flex"
                                      >
                                        <div className="AccHeA">
                                          <h6>
                                            {lesson.title} - ( {lesson.duration}
                                            )
                                          </h6>
                                        </div>
                                        <div className="ACCTim">
                                          {sessionIndex === 0 &&
                                          lessonIndex === 0 ? (
                                            <i
                                              className="ggrn"
                                              onClick={() =>
                                                handleShowModal(lesson)
                                              }
                                            >
                                              <BsPlayCircle />
                                            </i>
                                          ) : (
                                            <i className="red">
                                              <AiFillLock />
                                            </i>
                                          )}
                                        </div>
                                      </div>
                                    )
                                  )}
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </div>
                        ))}
                      </div>

                      <div className="FORCl">
                        <h4>Related courses</h4>
                        {relatedCourseList.map((relcourse, relcourseIndex) => {
                          return (
                            <div key={relcourseIndex} className="row">
                              <div className="MainCOurSEdIV d-grid">
                                <div className="couRSeImg relImg">
                                  <img
                                    src={relcourse.thumbnail}
                                    alt="Hepatology"
                                    className="img-fluid oragnsTop categriesImg"
                                  />
                                </div>
                                <div className="COurseConTEnt">
                                  <div
                                    onClick={() => {
                                      navigate(
                                        `/course-detail/${
                                          relcourse?._id
                                        }/${relcourse?.title.replace(
                                          / /g,
                                          "_"
                                        )}`
                                      );
                                      setFlag(!flag);
                                      window.scrollTo(0, 0);
                                    }}
                                  >
                                    <h5>{relcourse.title}</h5>
                                  </div>

                                  <span className="SAPnHr">
                                    <hr />
                                  </span>
                                  <div className="SCetionCourse d-flex relPrice">
                                    {/* <h4>
                                      {" "}
                                      {relcourse.price  === 0
                                        ? "FREE"
                                        : `₹${relcourse.price}`}
                                    </h4> */}
                                    <div className="RightCLcont d-flex crs striked">
                                      {isCourseFree(relcourse) ? (
                                        <h4>FREE</h4>
                                      ) : (
                                        <>
                                          {relcourse.discountedPrice > 0 ? (
                                            <>
                                              <div>
                                                <h4>
                                                  ₹{relcourse.discountedPrice}
                                                </h4>
                                              </div>
                                            </>
                                          ) : (
                                            <>
                                              <div>
                                                <h4>
                                                  ₹{relcourse.regularPrice}
                                                </h4>
                                              </div>
                                            </>
                                          )}
                                        </>
                                      )}
                                    </div>
                                    <h6>{relcourse.level}</h6>
                                    <h6 className="text-end">
                                      {relcourse.duration}
                                    </h6>
                                  </div>
                                  <span className="SAPnHr">
                                    <hr />
                                  </span>
                                  <div className="FinAlDIv d-flex justify-content-between">
                                    {item.totalRatings > 0 ? (
                                      <div>
                                        {[...Array(fullStars)].map(
                                          (star, starIndex) => (
                                            <FaStar
                                              key={starIndex}
                                              style={{ color: "#ffc107" }}
                                            />
                                          )
                                        )}
                                        {hasHalfStar && (
                                          <FaStarHalf
                                            style={{ color: "#ffc107" }}
                                          />
                                        )}
                                        <div
                                          className="ratings"
                                          style={{
                                            display: "inline-block",
                                            marginLeft: "10px",
                                          }}
                                        >
                                          {item.avgRating} ({item.totalRatings})
                                        </div>
                                      </div>
                                    ) : null}

                                    <div
                                      className="lrnMr"
                                      onClick={() => {
                                        navigate(
                                          `/course-detail/${
                                            relcourse._id
                                          }/${relcourse.title.replace(
                                            / /g,
                                            "_"
                                          )}`
                                        );
                                        setFlag(!flag);
                                        window.scrollTo(0, 0);
                                      }}
                                    >
                                      Learn More{" "}
                                      <i>
                                        <HiChevronDoubleRight />
                                      </i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}

      <Modal show={showModal} onHide={handleCloseModal} className="lsnVdo">
        <Modal.Header
          style={{
            textAlign: "center",
            background: "#107B38",
            color: "#fff",
            fontFamily: "Roboto, sans-serif",
            borderRadius: 0,
          }}
          closeButton
        >
          <Modal.Title
            style={{
              textAlign: "center",
              float: "unset",
              margin: "auto",
              marginLeft: "44%",
            }}
          >
            {selectedLesson ? selectedLesson.title : ""}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedLesson && (
            <video
              controls
              controlsList="nodownload"
              style={{ height: "100%", width: "100%" }}
            >
              <source src={selectedLesson.videoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default Courselist;
