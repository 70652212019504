import React, { useEffect, useRef, useState } from "react";
import "./Course.css";
import { Link, useParams } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { BsPlayCircle } from "react-icons/bs";
import { TiTick } from "react-icons/ti";
import dummy from "../../assets/images/dummy.jpeg";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import certificate from "../../assets/images/kareersity-certificate.png";
import Accordion from "react-bootstrap/Accordion";
import {
  Active_assessment_questionsForUser,
  Take_assessment,
  Update_watched_history_of_my_course,
  User_Assessment_Score,
  User_my_course_Info,
  viewActiveCourses,
} from "../../redux/courses";
import { useDispatch } from "react-redux";
import { loader } from "../../redux/common";
import { toast } from "react-toastify";
import ReactPlayer from "react-player";
import {
  User_existing_rating_and_reviews,
  User_give_rating_and_reviews,
} from "../../redux/user";
import playicon from "../../assets/images/playicon.png";

const Viewcourse = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [courseData, setCourseData] = useState([]);
  const [courseContents, setCourseContents] = useState([]);
  const [courseId, setCourseId] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [lessonId, setLessonId] = useState("");
  const [qList, setQList] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [userRR, setUserRR] = useState(null);
  const [userScore, setUserScore] = useState({});
  const [assCheck, setAssCheck] = useState({});
  const [rating, setRating] = useState(0);
  const [reviews, setReviews] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);
  const [showFullContent, setShowFullContent] = useState(false);
  const [refresher, setRefresher] = useState(false);
  const videoRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [totalDuration, setTotalDuration] = useState(0);
  const [assessmentStatus, setAssessmentStatus] = useState({});
  const [completedSessions, setCompletedSessions] = useState([]);

  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };

  useEffect(() => {
    dispatch(loader(true));
    dispatch(
      User_my_course_Info(params.purchasedCourseId, (response) => {
        if (response.status) {
          const courseIdFromResponse = response.data.courseId;
          const watchedHistory = response.data.watchedHistory;
          console.log(watchedHistory);
          if (!lessonId) {
            setLessonId(watchedHistory[0]?.lessons[0]?.lessonId);
          }

          setCourseId(courseIdFromResponse);
          setCourseContents(watchedHistory);
          dispatch(loader(false));
          dispatch(
            viewActiveCourses(courseIdFromResponse, (resp) => {
              if (resp.status) {
                setCourseData(resp.data);
                dispatch(loader(false));
              } else {
                dispatch(loader(false));
                toast.error(resp.message);
              }
            })
          );
        } else {
          dispatch(loader(false));
          toast.error(response.message);
        }
      })
    );
  }, [dispatch, params.purchasedCourseId, refresher]);
  console.log(lessonId);

  useEffect(() => {
    if (courseId) {
      // Ensure courseId is truthy before making the request
      const obj = { courseId: courseId };
      dispatch(loader(true));
      dispatch(
        User_existing_rating_and_reviews(obj, (resp) => {
          if (resp.status) {
            setUserRR(resp.data);
            dispatch(loader(false));
          } else {
            dispatch(loader(false));
          }
        })
      );
    }
  }, [dispatch, courseId]);

  useEffect(() => {
    if (sessionId) {
      setSessionId(sessionId);
      dispatch(
        User_Assessment_Score(sessionId, (response) => {
          if (response.status) {
            setUserScore({
              ...response.data,
              message: response.message,
            });
            // If user got full marks, add this session to completed sessions
            if (response.data.obtainedMarks === response.data.maximumMarks) {
              setCompletedSessions((prev) => [
                ...new Set([...prev, sessionId]),
              ]);
            }
            // Update assessment status based on the latest attempt
            setAssessmentStatus((prev) => ({
              ...prev,
              [sessionId]:
                response.data.obtainedMarks === response.data.maximumMarks,
            }));
          }
        })
      );
      dispatch(
        Active_assessment_questionsForUser({ sessionId }, (response) => {
          if (response.status) {
            setQList(response.data);
            dispatch(loader(false));
          } else {
            setQList([]);
            setAssCheck({
              sessionId: sessionId,
              isAssEx: false,
            });
            setRefresher(!refresher);
            dispatch(loader(false));
          }
        })
      );
    }
  }, [sessionId]);

  const handleSessionClick = (sessionId) => {
    if (sessionId) {
      setSessionId(sessionId);
      dispatch(
        User_Assessment_Score(sessionId, (response) => {
          if (response.status) {
            setUserScore({
              ...response.data,
              message: response.message,
            });
          }
        })
      );
      dispatch(
        Active_assessment_questionsForUser({ sessionId }, (response) => {
          if (response.status) {
            setQList(response.data);
            dispatch(loader(false));
          } else {
            setQList([]);
            setAssCheck({
              sessionId: sessionId,
              isAssEx: false,
            });
            setRefresher(!refresher);
            dispatch(loader(false));
          }
        })
      );
    }
  };

  const handleStarClick = (value) => {
    setRating(value); // Update the rating when a star is clicked
  };

  const handleReviewChange = (e) => {
    setReviews(e.target.value); // Update the reviews when the text area value changes
  };
  const handleOptionChange = (questionId, option) => {
    setSelectedAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: option,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const response = {
      sessionId: sessionId,
      qList: qList.map((question) => ({
        questionId: question._id,
        yourAnswer: selectedAnswers[question._id] || "",
      })),
    };

    dispatch(
      Take_assessment(response, (response) => {
        if (response.status) {
          setUserScore({
            ...response.data,
            message: response.message,
          });

          // Update assessment status for this session
          setAssessmentStatus((prev) => ({
            ...prev,
            [sessionId]: response.data.isFullScore,
          }));

          // If user got full marks, immediately update completed sessions
          if (response.data.obtainedMarks === response.data.maximumMarks) {
            setCompletedSessions((prev) => [...new Set([...prev, sessionId])]);
          }

          toast.success(response.message);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(response.message);
        }
      })
    );
  };

  const GiveRating = () => {
    const data = {
      courseId: courseId,
      rating: rating,
      reviews: reviews,
    };
    dispatch(
      User_give_rating_and_reviews(data, (resp) => {
        if (resp.status) {
          setUserRR(resp.data);
          dispatch(loader(false));
          toast.success(resp.message);
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };
  const updateVideoUrl = (newUrl) => {
    setVideoUrl(newUrl);
    console.log(newUrl);
  };
  const updateLessonId = (lessonId) => {
    setLessonId(lessonId);
  };
  const handlePlayClick = () => {
    setIsPlaying(true);
  };
  const handlePause = () => {
    setIsPlaying(false);
  };

  const playerConfig = {
    file: {
      forceVideo: true,
      disableSeek: true,
      attributes: {
        controlsList: "nodownload",
      },
    },
  };

  function formatDuration(seconds) {
    if (seconds === null || seconds === undefined) {
      return "00:00:00";
    }

    const hours = Math.floor(seconds / 3600)
      .toString()
      .padStart(2, "0");
    const minutes = Math.floor((seconds % 3600) / 60)
      .toString()
      .padStart(2, "0");
    const remainingSeconds = (seconds % 60).toString().padStart(2, "0");

    return `${hours}:${minutes}:${remainingSeconds}`;
  }

  const updateCurrentTimeAndDuration = () => {
    if (videoRef.current) {
      setCurrentTime(videoRef.current.getCurrentTime());
      setTotalDuration(videoRef.current.getDuration());
    }
  };

  const handleProgress = ({ played }) => {
    console.log(played);
    if (played === 1) {
      //console.log("params.purchasedCourseId && lessonId && isPlaying ", params.purchasedCourseId , lessonId ,isPlaying)
      if (params.purchasedCourseId && lessonId) {
        console.log("played");
        const data = {
          purchasedCourseId: params.purchasedCourseId,
          lessonId: lessonId,
          lessonWatchedDuration: currentTime,
          lessonTotalDuration: totalDuration,
        };
        dispatch(
          Update_watched_history_of_my_course(data, (resp) => {
            if (resp.status) {
              // setWatched(true);
              console.log("<p>You've watched this video.</p>");
              setRefresher(!refresher);
              dispatch(loader(false));
            } else {
              dispatch(loader(false));
            }
          })
        );
      }
    }
  };

  const handleTryAgain = () => {
    setSelectedAnswers({});
    setUserScore({});
  };

  return (
    <div className="CouREseLIstPA">
      {courseData.map((x, i) => (
        <>
          <div className="PageHEaders COUrseLi">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="PageTi  pt-5 pb-5">
                    <p>
                      <Link to="/">Home</Link>{" "}
                      <i>
                        <FaAngleRight />
                      </i>
                      <Link to="/mycourse">My Course</Link>
                      <i>
                        <FaAngleRight />
                      </i>
                      <Link to="/view_my_course/">{x.title}</Link>
                    </p>
                    <h2>{x.title}</h2>
                    <p className="Coursetitlegrn">
                      1.{courseContents[0]?.title}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="CorLiSt pt-5 pb-5">
            <div className="container">
              <div className="row">
                <div className="col-12" style={{ position: "relative" }}>
                  <div className="viewCl">
                    <ReactPlayer
                      className="img-fluid"
                      url={videoUrl || x.session[0]?.lessons[0]?.videoUrl}
                      ref={videoRef}
                      controls={isPlaying}
                      width="82.5vw"
                      height="auto"
                      onPause={handlePause}
                      onProgress={handleProgress}
                      playing={isPlaying}
                      config={playerConfig}
                    />
                  </div>
                  <div className="PlyIcon preview v1">
                    {!isPlaying && (
                      <img
                        className="ggrn"
                        src={playicon}
                        alt="Play Icon"
                        onClick={handlePlayClick}
                      />
                    )}
                  </div>
                </div>
                <div className="col-lg-5 col-md-12 ">
                  <div className="entireCont">
                    <div className="RightC">
                      <div className="d-flex mb-3">
                        <div className="ClRConteNt">
                          <h4>Course Content</h4>
                        </div>
                        <div className="Hrs">
                          {x?.duration && (
                            <h5>
                              {x.duration.split(":")[0]}h{" "}
                              {x.duration.split(":")[1]}m{" "}
                              {x.duration.split(":")[2]}s{" "}
                            </h5>
                          )}
                        </div>
                      </div>
                      <hr />
                      <div className="ACCclRight">
                        <Accordion defaultActiveKey={i} alwaysOpen>
                          {courseContents.map((y, j) => {
                            // Check if this session is accessible
                            const isAccessible =
                              j === 0 ||
                              completedSessions.includes(
                                courseContents[j - 1].sessionId
                              );

                            return (
                              <Accordion.Item
                                eventKey={y.sessionId}
                                onClick={() =>
                                  isAccessible &&
                                  handleSessionClick(y.sessionId)
                                }
                                className={
                                  !isAccessible ? "disabled-accordion" : ""
                                }
                              >
                                <Accordion.Header
                                  disabled={!isAccessible}
                                  style={
                                    !isAccessible
                                      ? { opacity: 0.5, cursor: "not-allowed" }
                                      : {}
                                  }
                                >
                                  {j + 1}. {y.title}
                                  {!isAccessible && (
                                    <span className="locked-text">
                                      (Complete previous session first)
                                    </span>
                                  )}
                                </Accordion.Header>
                                {isAccessible &&
                                  y?.lessons.map((less, lesInd) => (
                                    <Accordion.Body>
                                      <div className="AccContEnt d-flex">
                                        <div className="AccHeA">
                                          <h6>
                                            {lesInd + 1}. {less?.title} - (
                                            {formatDuration(
                                              less?.lessonTotalDuration
                                            )}
                                            )
                                          </h6>
                                        </div>
                                        <div className="ACCTim">
                                          <i
                                            className="ggrn"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              handlePlayClick(); // Start playing
                                              updateVideoUrl(less?.videoUrl); // Set the video URL
                                              updateLessonId(less?.lessonId); // Set the video URL
                                            }}
                                          >
                                            <BsPlayCircle />
                                          </i>
                                        </div>
                                      </div>
                                    </Accordion.Body>
                                  ))}
                              </Accordion.Item>
                            );
                          })}
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 col-md-12 ">
                  <div className="CoRTabS">
                    <Tabs defaultActiveKey="first" className="viewcor">
                      <Tab eventKey="first" title="Overview">
                        <div className="OveRView pt-4 pb-4">
                          <h4>Course description</h4>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: showFullContent
                                ? x.shortDescription
                                : x.shortDescription.slice(0, 250), // Display the first 100 characters
                            }}
                          ></p>
                          {x.shortDescription.length > 200 && (
                            <Link to="#" onClick={toggleContent}>
                              {showFullContent ? "Show Less" : "Show More"}
                            </Link>
                          )}
                        </div>

                        <hr />
                        <div className="CLwhat">
                          <h4>What you'll learn</h4>
                          <div className="row">
                            {(x?.whatWillYouLearn)
                              .replace(/<\/?[^>]+(>|$)/g, "")
                              .split(",")
                              .map((w, wi) => (
                                <div className="col-lg-6 col-md-12">
                                  <ul className="LearNList">
                                    <li>
                                      <i>
                                        <TiTick />
                                      </i>
                                      {w}
                                    </li>
                                  </ul>
                                </div>
                              ))}
                          </div>
                        </div>

                        <hr />
                        <div className="EDClist">
                          <h4>Educator</h4>
                          <div className="EdConT mb-4">
                            <div className="row">
                              <div className="col-lg-3 col-md-12">
                                <div className="EDImG">
                                  <img
                                    src={
                                      x.educators && x.educators.photoUrl
                                        ? x.educators.photoUrl
                                        : dummy
                                    }
                                    alt="Educator PP"
                                    className="img-fluid"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-9 col-md-12">
                                <div className="EdConT">
                                  <h4>{x?.educators.name}</h4>
                                  <h6>
                                    {x?.educators.expertise}{" "}
                                    {x?.educators.designation}
                                  </h6>
                                  <div className="ECLD d-flex">
                                    <p>
                                      <i>
                                        <AiFillStar />
                                      </i>{" "}
                                      {x.avgRating} ( {x.totalRatings} Rating ){" "}
                                      {x.totalStudents} Students{" "}
                                    </p>
                                    <p>{x.totalReviews} Review</p>
                                    <p>{x?.educators.countCourses} Courses</p>
                                  </div>
                                  <p>{x?.educators.description}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="OveRView pt-4 pb-4">
                          <h4>Who this course is for</h4>

                          <p
                            dangerouslySetInnerHTML={{
                              __html: showFullContent
                                ? x?.whoThisCourseIsFor
                                : x?.whoThisCourseIsFor.slice(0, 100), // Display the first 100 characters
                            }}
                          ></p>
                          {x?.whoThisCourseIsFor.length > 200 && (
                            <Link to="#" onClick={toggleContent}>
                              {showFullContent ? "Show Less" : "Show More"}
                            </Link>
                          )}
                        </div>
                        <hr />
                        <div className="ValuCoN">
                          <h4>Course Includes</h4>
                          <div className="row ">
                            {x?.courseIncludes.map((topic, index) => (
                              <div className="col-lg-6 col-md-12 col-sm-12">
                                <ul className="LearNList">
                                  <li>
                                    <i>
                                      <TiTick />
                                    </i>
                                    {topic}
                                  </li>
                                </ul>
                              </div>
                            ))}
                          </div>
                        </div>
                      </Tab>
                      <Tab eventKey="second" title="Write Review">
                        <div className="OveRView pt-4 pb-4">
                          {userRR && userRR.rating && userRR.reviews ? (
                            <>
                              <h4>
                                Thank you ! We appreciate your efforts in making
                                Kareer Sity better!!!
                              </h4>
                              <div className="view grn box mt-3">
                                <p>Overall Rating</p>
                                <div className="StarFIVe viewC mb-3">
                                  {[1, 2, 3, 4, 5].map((starPosition) => (
                                    <i
                                      key={starPosition}
                                      name="rating"
                                      style={{ cursor: "pointer" }}
                                    >
                                      {starPosition <= userRR.rating ? (
                                        <AiFillStar />
                                      ) : (
                                        <AiOutlineStar />
                                      )}
                                    </i>
                                  ))}
                                </div>

                                <p>Review</p>
                                <p>{userRR.reviews}</p>
                              </div>
                            </>
                          ) : (
                            <>
                              <h4>Kindly leave a review and rating</h4>
                              <div className="view grn box mt-3">
                                <p>Overall Rating</p>
                                <div className="StarFIVe viewC mb-3">
                                  <i
                                    name="rating"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleStarClick(1)}
                                  >
                                    {rating >= 1 ? (
                                      <AiFillStar />
                                    ) : (
                                      <AiOutlineStar />
                                    )}
                                  </i>
                                  <i
                                    name="rating"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleStarClick(2)}
                                  >
                                    {rating >= 2 ? (
                                      <AiFillStar />
                                    ) : (
                                      <AiOutlineStar />
                                    )}
                                  </i>
                                  <i
                                    name="rating"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleStarClick(3)}
                                  >
                                    {rating >= 3 ? (
                                      <AiFillStar />
                                    ) : (
                                      <AiOutlineStar />
                                    )}
                                  </i>
                                  <i
                                    name="rating"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleStarClick(4)}
                                  >
                                    {rating >= 4 ? (
                                      <AiFillStar />
                                    ) : (
                                      <AiOutlineStar />
                                    )}
                                  </i>
                                  <i
                                    name="rating"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleStarClick(5)}
                                  >
                                    {rating === 5 ? (
                                      <AiFillStar />
                                    ) : (
                                      <AiOutlineStar />
                                    )}
                                  </i>
                                </div>
                                <p>Review</p>
                                <textarea
                                  className="mb-4"
                                  rows="5"
                                  value={reviews}
                                  onChange={handleReviewChange}
                                ></textarea>
                                <br></br>
                                <buttton
                                  className="grn-btn1 mb-5"
                                  style={{ cursor: "pointer" }}
                                  onClick={GiveRating}
                                >
                                  Submit
                                </buttton>
                              </div>
                            </>
                          )}
                        </div>
                      </Tab>
                      <Tab eventKey="third" title="Assessment">
                        {courseContents?.map((ss, ssi) => {
                          const isFullScore =
                            assessmentStatus[ss.sessionId] ||
                            (userScore?.sessionId === ss.sessionId.toString() &&
                              userScore?.obtainedMarks ===
                                userScore?.maximumMarks);
                          const hasAttempted =
                            userScore?.sessionId === ss.sessionId.toString();

                          if (ss.isSessionCompleted === true) {
                            return (
                              <div key={ss.sessionId}>
                                <h4 className="grntiTle">
                                  {ssi + 1}. {ss.title}
                                </h4>

                                {hasAttempted && !isFullScore && (
                                  <div className="qaAssesment viw mb-4">
                                    <p className="text-warning">
                                      {userScore.message} Your score is{" "}
                                      {userScore.obtainedMarks}/
                                      {userScore.maximumMarks}
                                    </p>
                                    <button
                                      className="grn-btn1 mb-3"
                                      onClick={handleTryAgain}
                                    >
                                      Try Again
                                    </button>
                                  </div>
                                )}

                                {isFullScore ? (
                                  // Show completed assessment with answers and highlight correct options
                                  <>
                                    <div className="qaAssesment viw mb-4">
                                      <p className="text-success">
                                        Congratulations! You've achieved a
                                        perfect score! Your score is{" "}
                                        {userScore.obtainedMarks}/
                                        {userScore.maximumMarks}
                                      </p>
                                    </div>
                                    {qList
                                      .filter(
                                        (question) =>
                                          question.sessionId === ss.sessionId
                                      )
                                      .map((question, index) => (
                                        <div
                                          className="qaAssesment viw"
                                          key={index}
                                        >
                                          <p>
                                            {index + 1}. {question.question}
                                          </p>
                                          <div className="row">
                                            {question?.options.map(
                                              (op, opi) => (
                                                <div
                                                  className="col-lg-3 col-md-6 d-flex align-items-center justify-content-start mb-4"
                                                  key={opi}
                                                >
                                                  <input
                                                    type="radio"
                                                    disabled
                                                    checked={
                                                      op ===
                                                      question.correctAnswer
                                                    }
                                                  />
                                                  <label
                                                    className={
                                                      op ===
                                                      question.correctAnswer
                                                        ? "text-success fw-bold"
                                                        : ""
                                                    }
                                                    style={{
                                                      marginLeft: "8px",
                                                      ...(op ===
                                                        question.correctAnswer && {
                                                        color: "#28a745",
                                                        fontWeight: "bold",
                                                      }),
                                                    }}
                                                  >
                                                    {op}
                                                    {op ===
                                                      question.correctAnswer &&
                                                      " ✓"}
                                                  </label>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      ))}
                                  </>
                                ) : (
                                  // Assessment form for retrying
                                  <form
                                    onSubmit={handleSubmit}
                                    onClick={() =>
                                      handleSessionClick(ss.sessionId)
                                    }
                                  >
                                    {qList
                                      .filter(
                                        (question) =>
                                          question.sessionId === ss.sessionId
                                      )
                                      .map((question, index) => (
                                        <div
                                          className="qaAssesment viw"
                                          key={index}
                                        >
                                          <p>
                                            {index + 1}. {question.question}
                                          </p>
                                          <div className="row">
                                            {question?.options.map(
                                              (op, opi) => (
                                                <div
                                                  className="col-lg-3 col-md-6 d-flex align-items-center justify-content-start mb-4"
                                                  key={opi}
                                                >
                                                  <input
                                                    type="radio"
                                                    name={question._id}
                                                    value={op}
                                                    checked={
                                                      selectedAnswers[
                                                        question._id
                                                      ] === op
                                                    }
                                                    onChange={() =>
                                                      handleOptionChange(
                                                        question._id,
                                                        op
                                                      )
                                                    }
                                                  />
                                                  <label>{op}</label>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      ))}

                                    {qList.filter(
                                      (question) =>
                                        question.sessionId === ss.sessionId
                                    ).length > 0 && (
                                      <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                          <button className="cncBtn">
                                            Cancel
                                          </button>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                          <button className="subBtn">
                                            Submit
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </form>
                                )}
                              </div>
                            );
                          }
                          return null;
                        })}
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ))}
    </div>
  );
};
export default Viewcourse;
