import React from "react";
import { Link } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";
import "./PrivacyPolicy.css";
const Privacypolicy = () => {
  return (
    <div className="AbouTPAge">
      <div className="PageHEaders">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="PageTi pt-5">
                <h2>Privacy Policy</h2>
                <p>
                  <Link to="/">Home</Link>{" "}
                  <i>
                    <FaAngleRight />
                  </i>
                  <Link to="/privacypolicy">Privacy Policy</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="policysect pt-5 pb-5">
        <div className="container">
          <div className="col-lg-12">
            <h3>Introduction</h3>

            <p className="policypara">
              This Privacy Policy relates to the collection, use and disclosure
              of personal data, including special or sensitive personal data, by
              KareerSity (“KareerSity “, “KareerSity”, “we“, or “our“). Personal
              Data is information relating to an individual (“you“ or “your“) as
              more fully defined herein below. KareerSity is committed to
              protecting your privacy and ensuring that you have a secured
              experience on our website and while using our products and
              services (collectively, “Products“). This policy outlines
              KareerSity 's, its subsidiaries and affiliated companies handling
              practices and how we collect and use the information you provide
              in the course of your use or access of our systems through online
              interfaces (e.g. website, mobile applications etc.) (collectively
              “Company Systems''). In this Privacy Policy, “Personal Data''
              means any information that can be used to individually identify a
              person and may include, but is not limited to, name, email
              address, postal or other physical addresses, title, and other
              personally identifiable information. By using our services or
              products, it will be deemed that you have read, understood and
              agreed to be bound by this policy detailed hereunder We will be
              the processor of the Personal Data that is provided, collected
              and/or processed pursuant to this policy, except where otherwise
              expressly stated.
            </p>

            <h3>Applicability of the Policy</h3>

            <p>
              This policy shall apply to all information we collect through the
              Company Systems and/or in the course of your use of the services
              of KareerSity.
            </p>
            <p>
              This policy does not apply to, nor does KareerSity take any
              responsibility for, any information that is collected by any third
              party either using Company Systems or through any links on the
              Company Systems or through any of the advertisements or through
              BOTS
            </p>

            <h3>Information We Collect</h3>

            <p>
              We will only collect information which you share with us. You can
              choose what information you want to share with us. We will collect
              your information only if you choose to share it through the
              Company Systems. We will collect the following information about
              you or related to your use of the Company’s Systems:
            </p>

            <ul>
              <li>
                Any personal or PII that you may submit or may be required to
                submit for registration and continued use of the Company Systems
                or receive the services by KareerSity ;
              </li>
              <li>
                Your name, title, gender, date of birth, email address,
                telephone number (Home/work/mobile telephonic number),
                profile/display picture, login name, screen name, or handle,
                country/state/postcode or city of residence, postal or other
                physical address, name(s) of the school/university (including
                grades and graduation year),name(s) of the workplace, job
                position/designation (including salary), resume/CV, information
                related to social profiles, such as Facebook, Google, etc., IP
                addresses and other information collected passively (as further
                detailed in the “Passive Collection” section below), may be
                collected; and
              </li>
              <li>
                Any other information you may choose to further provide us,
                without limitation, any information to update your account and
                profile, if required, to fill out any forms, provide your
                feedback to surveys, write any articles on the Company Systems,
                or to use any features of Company Systems.
              </li>
            </ul>

            <p>
              We collect and/or process your Personal Data as a part of the
              following activities related to our Products:
            </p>

            <ul>
              <li>
                Account registration, use of certain KareerSity features,
                creating or taking tests, generating reports based on
                information collected from use of our Products.
              </li>
              <li>
                Requesting service and support for our Products and providing
                such support, registering for an event, participating in an
                online survey, participating in discussion groups or forums.
              </li>
              <li>
                Registering for newsletter subscriptions, customizing the
                content you see as per relevance.
              </li>
            </ul>

            <p>
              Please do not include any personal information, personally
              identifiable information or sensitive personal information unless
              specifically requested by KareerSity as part of the registration
              or other applicable processes. If KareerSity determines that any
              information you have provided or uploaded violates the terms of
              this policy, KareerSity shall have the right, in its absolute
              discretion, to delete such information without incurring any
              liability. We may also periodically use your information received
              from your affiliated entities, business partners and other
              third-party sources and combine it with your account information.
              For example: if you access or login to our Company Systems via a
              third-party source like Facebook, Twitter, LinkedIn, we may
              receive your registration information, updated information and
              combine that with information we collect through our Company
              Systems or add it to your account information on our Company
              Systems.
            </p>

            <h3>Policy for NDNC</h3>

            <p>
              By using the Website and/or registering yourself at
              www.kareersity.com you authorise us to contact you via email or
              phone call or sms and offer you our services, imparting product
              knowledge, offer promotional offers running on website & offers
              offered by the associated third parties, for which reasons,
              personally identifiable information may be collected. And
              irrespective of the fact if you have also registered yourself
              under DND or DNC or NCPR service, you still authorise us to give
              you a call from KareerSity for the above mentioned purposes till
              365 days of your registration with us.
            </p>
            <p>
              This Privacy Policy covers KareerSity treatment of personally
              identifiable information that KareerSity collects when you are on
              the KareerSity site, and when you use KareerSity services. This
              policy also covers KareerSity treatment of personally identifiable
              information that KareerSity business partner shares with
              KareerSity. This policy does not apply to the certain KareerSity
              products or services, when you visit KareerSity pages, and when
              you enter promotions. KareerSity may also receive personally
              identifiable information from our business partners. When you
              register with KareerSity, we ask for your first name, last name
              and number registered on Whatsapp.
            </p>
            <p>
              Once you register with KareerSity and sign in to our services, you
              are not anonymous to us. Also during registration, you may be
              requested to register your mobile phone and email id, pager, or
              other device to receive text messages, and other services to your
              wireless device. By registration you authorise us to send
              sms/email alerts to you for your login details and any other
              service requirements or some advertising messages/emails from us.
            </p>

            <h3>Copyright Infringement Notice</h3>
            <p>
              If a User believes in good faith that his/her copyright has been
              infringed, the User may provide us with a written communication
              which contains:
            </p>
            <ul>
              <li>
                An electronic or physical signature of the person authorized to
                act on behalf of the owner of the copyright interest/work;
              </li>
              <li>
                A description of the copyrighted work that the User claims has
                been infringed upon;
              </li>
              <li>
                A description of where the material that the User claims is
                infringing is located on the Platform, and any other details
                identifying the infringing material;
              </li>
              <li>The User’s email address, address and telephone number;</li>
              <li>
                A statement by the User affirming that the User has a good faith
                belief that the disputed use is not authorized by the copyright
                owner, his/her agent, or the law; and
              </li>
              <li>
                A statement by the User, made under penalty of perjury, that the
                information in the copyright notice is accurate and that the
                User is the copyright owner or authorized to act on behalf of
                the copyright owner.
              </li>
              <li>
                The Copyright infringement notice needs to be sent to the
                following address:
                <br /> Attention: Ramesh Juneja
                <br /> Address: KareerSity, No 22, Vraj Bhoomi complex,
                Brahmand, Thane west, Maharashtra 400607
                <br /> Email: askus@kareersity.com
              </li>
            </ul>

            <h3>Processing your Personal Data</h3>

            <p>
              We will use your Personal Data only in accordance with this
              policy. To enable us to fulfil the contract between us for the
              services you have requested, we need to process your Personal Data
              for purposes including the following:
            </p>
            <ul>
              <li>Account registration</li>
              <li>Use of our website.</li>
              <li>Creating or taking online tests.</li>
              <li>
                Generating reports based on information collected from use of
                our Products.
              </li>
              <li>
                Requesting service and support for our Products and providing
                such support.
              </li>
              <li>Participating in discussion groups or forums.</li>
              <li>Customizing the content you see as per relevance.</li>
            </ul>

            <p>We process Personal Data for purposes such as:</p>

            <ul>
              <li>
                Providing reports based on information collected from use of our
                Products.
              </li>
              <li>
                Keeping you up-to-date about latest Product announcements,
                software updates, software upgrades, system enhancements,
                special offers, and other information.
              </li>
              <li>Providing support and assistance for our Products</li>
              <li>Providing the ability to create a personal profile.</li>
              <li>Providing the ability to contact you, if required.</li>
              <li>Providing customer feedback and support.</li>
              <li>
                Providing and administering opt-in events or other marketing or
                promotional activities on KareerSity.
              </li>
              <li>
                Being able to conduct questionnaires and surveys in order to
                provide you with better products and services.
              </li>
              <li>Supporting recruitment inquiries.</li>
              <li>
                Personalizing marketing communication and website content based
                on your preferences, such as in response to your request for
                specific information on products and services that may be of
                interest to you.
              </li>
            </ul>

            <h3>Consent</h3>

            <p>
              Your decision to provide Personal Data to KareerSity and/or
              KareerSity is at your sole discretion and is deemed obtained when
              you register and create your account on our Company Systems.
              Please note that you may not be able to access certain options,
              offers, and services if they require Personal Data that you have
              not provided. You can sign-up, and therefore consent, to receive
              email or newsletter communication from us. If you would like to
              discontinue receiving this communication, you can update your
              preferences by using the ‘Unsubscribe’ link at the end of such
              emails or by contacting us through email on askus@kareersity.com
            </p>

            <h4>How do we use your information?</h4>

            <p>
              KareerSity will use your Personal Data only in accordance with
              this policy. If you do not wish us to continue using your Personal
              Data in this manner you can request for deactivation of your
              account from account settings.
            </p>
            <p>
              We will only process your Personal Data if we have a lawful basis
              for doing so, which includes but is not limited to the following:
              consent, contractual necessity (i.e. processing that is necessary
              for the performance of a contract with you, such as your user
              agreement with us that allows us to provide you with the Products)
              and our legitimate interests or the legitimate interest of others
              (e.g. our users) such as:
            </p>

            <ul>
              <li>
                Provide you with the websites and services, together with any
                support you may request.
              </li>
              <li>Respond to your inquiries or fulfil your requests.</li>
              <li>Diagnose Website and Service technical problems.</li>
              <li>
                Send you information that we believe may be of interest to you,
                such as Service.
              </li>
              <li>
                Announcements, newsletters, educational materials, and event
                information.
              </li>
              <li>
                Send you administrative information such as notices related to
                the Services or policy changes.
              </li>
              <li>
                Understand how the Websites and Services are being used in order
                to enhance and optimize them.
              </li>
              <li>
                Prevent, detect, mitigate, and investigate fraudulent or illegal
                activity.
              </li>
              <li>
                As described to you at the point of collection of the
                information.
              </li>
              <li>
                Complying with our legal obligations, resolving disputes with
                users, enforcing our agreements.
              </li>
              <li>
                Protecting, investigating and deterring against fraudulent,
                harmful, unauthorized or illegal activity.
              </li>
            </ul>

            <h4>How do we organize & store your data?</h4>

            <ul>
              <li>
                AWS Relational Database - Main database storing all User
                information.
              </li>
              <li>
                ElasticSearch - Indexed resumes. We use it to query on text
                written in a user's resume.
              </li>
              <li>Sentry - Used to monitor errors.</li>
              <li>
                New Relic - Stores server logs which are used in optimization of
                our services.
              </li>
              <li>S3 - Server logs and User resume files.</li>
              <li>
                Google Analytics - The third-party services described in this
                section enable KareerSity to monitor and analyze Website and
                Service traffic. Google Analytics (Google Inc.) Google Analytics
                is a web analysis service provided by Google Inc. Google
                utilizes the information collected to track and examine the use
                of the Websites and Services to prepare reports that may be used
                for optimization of the Websites and Services. Information
                collected: Cookies and usage data.
              </li>
              <li>Place of processing: India.</li>
            </ul>

            <h4>Your Rights:</h4>

            <ul>
              <li>
                Rights:You have certain rights with respect to your Personal
                Data as mentioned below.
              </li>
              <li>
                Access: You can request more information about the Personal Data
                we hold about you. You can also request a copy of the Personal
                Data.
              </li>
              <li>
                Correction: If you believe that any Personal Data we are holding
                about you is incorrect or incomplete, you can request that we
                correct or supplement such data. You can also correct some of
                this information directly through your user profile or
                profile/account settings after logging into www.KareerSity.org.
                Please contact us as soon as possible upon noticing any
                inaccuracies or incompleteness.
              </li>
              <li>
                Objection: You can contact us to let us know that you object to
                the collection or use of your Personal Data for certain
                purposes.
              </li>
              <li>
                Erasure: You can request that we delete some or all of your
                Personal Data from our systems. While this will be done
                immediately, residual data may be saved in certain logs and this
                will be purged within a year after deleting your data. Once your
                data is deleted, you will no longer have an account with
                KareerSity.
              </li>
              <li>
                Restrictions: You can always request us to deactivate your
                KareerSity account to restrict further processing of your
                Personal Data.
              </li>
              <li>
                Portability: You have the right to ask for a copy of your
                Personal Data in a machine-readable format
              </li>
              <li>
                Withdrawal of consent: If we are processing your Personal Data
                based on your consent (as indicated at the time of collection of
                such data), you have the right to withdraw your consent at any
                time. Please note, however, that if you exercise this right, you
                may have to then provide express consent on a case-by-case basis
                for the use or disclosure of certain of your Personal Data, if
                such use or disclosure is necessary to enable you to utilize
                some or all of our Products.
              </li>
            </ul>

            <h3>Retention</h3>

            <p>
              Your data is retained with KareerSity as long as you have an
              active account on the Company Systems. You can request that we
              delete some or all of your Personal Data from our systems. While
              this will be done within thirty (30) days from the date of such
              request, residual data may be saved in certain logs and this will
              be purged within a year after deleting your data. Once your data
              is deleted, you will no longer have an account with KareerSity .
            </p>

            <h4>Passive Data collection:</h4>

            <p>
              KareerSity might automatically collect some data about you when
              you are using any of our products, using methods like cookies and
              other tracking technologies. Information automatically collected
              includes cookies, page views, geolocation data, IP(internet
              protocol) addresses, browser and OS(Operating System) type, ISP
              (Internet Service Provider), files viewed and downloaded from our
              websites, referral and exit pages and click stream data. Such data
              does not have an expiry date and we do not delete this data. This
              data is stored in the form of logs or in third party tracking and
              analytics softwares like Google analytics, Linkedin Insights etc.
            </p>

            <h4>We Use Cookies:</h4>
            <p>
              KareerSity uses cookies to help us remember and process the items
              in your shopping cart. They are also used to help us understand
              your preferences based on previous or current site activity, which
              enables us to provide you with improved services. We also use
              cookies to help us compile aggregate data about site traffic and
              site interaction so that we can offer better site experiences and
              tools in the future. Further, we may use cookies or other tracking
              technologies to analyze trends, track users’ movements around the
              website, and gather information about our user base, such as
              location information based on IP addresses.
            </p>
            <p>
              You can choose to have your computer warn you each time a cookie
              is being sent, or you can choose to turn off all cookies. You do
              this through your browser (like Internet Explorer) settings. Each
              browser is a little different, so look at your browser's Help menu
              to learn the correct way to modify your cookies.
            </p>
            <p>
              If you disable cookies off, some features will be disabled. It
              won't affect the user's experience that makes your site experience
              more efficient and some of our services will not function
              properly.
            </p>
            <p>
              KareerSity may collect information related to visitors and
              machines. Such information includes cookies, IP addresses,
              pageview activities, and geolocation data. The details of user
              activities on our website such as number of visits, time spent on
              our website, and pages clicked are also collected.
            </p>

            <h4>To whom we may disclose your Personal Data:</h4>
            <h5>General</h5>
            <p>
              The information you provide on the Company Systems may be
              disclosed by KareerSity to its employees, agents, representatives,
              consultants, subsidiaries, affiliates, and third-party providers
              who require the information for the purposes of (a) operating and
              maintaining the Company Systems, (b) data processing or storage.
            </p>

            <h5>Hiring Partners</h5>
            <p>
              We may provide your data to our hiring partners after taking your
              consent. We provide resumes, phone numbers, email addresses and
              other relevant information to our hiring partners to enable users
              to get job offers. Events of Reorganization: In any given
              instance, if KareerSity undergoes any events of reorganization,
              merger, acquisition, insolvency or bankruptcy, KareerSity may
              sell, transfer or share some or all of our assets, including your
              information, subject to notification to You pertaining to transfer
              of Your information. Applicable Laws: KareerSity will comply with
              requests and directions of all governmental, law enforcement,
              court or regulatory authorities, which it believes in good faith
              to be in accordance with any applicable law. Such compliance may
              include disclosing to the governmental, law enforcement, courts,
              or regulatory authorities’ information submitted by you or
              collected in relation to your use of the Company Systems or
              KareerSity 's services including any personally identifiable
              information. By using the Company Systems and receiving KareerSity
              services, you consent to KareerSity ’s disclosure of information
              as set out in this Section herein to governmental, law
              enforcement, courts or regulatory authorities KareerSity will not
              publish, sell or rent Your personal information to third-parties
              for their marketing purposes without Your explicit consent.
            </p>

            <h5>Data Security</h5>
            <p>
              The information that you provide, subject to disclosure in
              accordance with this policy, shall be maintained in a safe and
              secure manner. Your information shall be protected, to a
              commercially reasonable extent, against unauthorized access, use,
              or disclosure. Our databases and information are stored on secure
              servers with appropriate firewalls. Further, we use vulnerability
              scanning and scanning to PCI standards annually and our Company
              Systems are subject to regular Malware Scanning. Additionally, we
              use SSL certificates to encrypt all the data being transferred. As
              a user of the Company Systems, you have the responsibility to
              ensure data security. You must not disclose to any person the
              authentication parameters that are assigned to you including Your
              username or password for your use of the Company Systems. You
              acknowledge that you will be solely responsible for all acts
              committed by use of your username /other authentication
              parameters.
            </p>
            <p>
              Given the nature of internet transactions, KareerSity does not
              take any responsibility for the transmission of information
              collected from you or are generated by your use of the Company
              Systems or the services. Any transmission of such information over
              the internet is done at your sole risk. KareerSity does not take
              any responsibility for you or any third party circumventing the
              privacy settings or security measures contained on the Company
              Systems.
            </p>
            <p>
              Notwithstanding anything to the contrary, while KareerSity will
              use all reasonable efforts to ensure that any information
              collected from you or are generated by your use of the Company
              Systems or the services is safe and secure, it offers no
              representations or warranties that the security measures are
              adequate, safe, fool proof or impenetrable.
            </p>

            <h4>Integration with Linked Websites and Third-Party Websites</h4>
            <p>
              Links to external, or third-party websites, may be provided solely
              for your convenience. Such links from us to an external website
              does not imply or mean that KareerSity endorses or accepts any
              responsibility for the content or the use of such a website.
              KareerSity does not give any representation regarding the quality,
              safety, suitability, or reliability of any external websites or
              any of the content or materials contained in them. It is important
              for you to take necessary precautions, especially to ensure
              appropriate safety from viruses, worms, Trojan horses and other
              potentially destructive items.
            </p>

            <h4>Third-party Ad Networks</h4>
            <p>
              Our Website may use third party network advertisers to display
              advertisements about our Products on third party websites, based
              on your visits to our site as well as other websites. This enables
              us and these third parties to target advertisements by displaying
              ads for our Products which might interest you.
            </p>
            <p>
              Third party network advertisers’ services may use cookies,
              JavaScript, and other technologies to make their ads effective and
              to personalize them to connect to you. These third-party cookies
              and other technologies are governed by each third party’s specific
              privacy policy, and not this one. We may provide these third-party
              advertisers with anonymized and aggregated information about your
              usage of our website and our Products; however, we do not share
              your Personal Data with these third parties. You also have the
              option of denying the access of data to such third-party ad
              networks.
            </p>

            <h4>Anonymized Information</h4>
            <p>
              Notwithstanding anything to the contrary in this policy,
              KareerSity may use any information provided to KareerSity in
              relation to or as part of the services in providing services to
              its other clients and to develop and create reports, statistical
              analysis, and benchmarking analyses for its clients provided that
              such reports contain only anonymous, aggregated data and do not
              identify you by name.
            </p>

            <h4>Age Restrictions</h4>
            <p>
              You explicitly agree you are 18 years of age or older, unless
              represented by a parent or legal guardian. If you are not of the
              requisite age you must not provide any information to KareerSity
              directly or by way of usage of the Company Systems. If KareerSity
              determines that it is in possession of any information belonging
              to an individual below 18 years of age which is submitted,
              collected or generated in breach of the terms of this Policy, it
              will delete the same without any notice to the individual to whom
              such information belongs to.
            </p>

            <h3>Update</h3>
            <p>
              This policy may be updated from time-to-time, so we recommend that
              you regularly review this policy each time you return to our
              website.
            </p>

            <h3>Grievance</h3>
            <p>
              If you have any issue or grievance with respect to our policy or
              with the manner in which we collect or store your information, or
              in any respect related to your personal information provided to
              us, please contact us at care@KareerSity.org. We will do
              everything we reasonably can to ensure that your grievance is
              attended to and addressed within a period of 30 days from the date
              of receipt of Your grievance.
            </p>

            <h3>Contact</h3>
            <p>
              If you have any questions about our policy or related dealings
              with us or would like further information about our services and
              practices, you can contact us at care@KareerSity.org. This policy
              must be read in conjunction with the other agreements you may
              enter into with KareerSity and the ToS as published by KareerSity
              on KareerSity 's website. By accepting the policy, you expressly
              consent to KareerSity 's use and disclosure of your personal
              information in accordance with this policy.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Privacypolicy;
