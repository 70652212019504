import React, { useEffect, useState } from "react";
import "./Educator.css";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { educatorRegisterCb } from "../../redux/user";
import { loader } from "../../redux/common";
import { useDispatch } from "react-redux";
import { categoryList } from "../../redux/courses";
import { AiFillCamera } from "react-icons/ai";
import Profimage from "../../assets/images/userimage.png";
import { Link } from "react-router-dom";
const Educator = () => {
  const dispatch = useDispatch();
  const [categoryListData, setCategoryListData] = useState([]);
  const [previewImage, setPreviewImage] = useState("");
  const [file, setFile] = useState("");

  const {
    register: register,
    formState: { errors: errors },
    reset: reset,
    handleSubmit: handleSubmit,
  } = useForm({ mode: "onBlur" });

  const signUpFn = (data) => {
    const formData = new FormData();

    // Append existing fields to formData
    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("phoneNumber", data.phoneNumber);
    formData.append("city", data.city);
    formData.append("expertise", data.expertise);
    formData.append("designation", data.designation);
    formData.append("description", data.description);
    formData.append("cvUrl", data.cvUrl[0]);
    formData.append("course1Url", data.course1Url[0]);
    formData.append("photoUrl", file);

    // Append new fields to formData
    formData.append("fullName", data.fullName);
    formData.append("accountNumber", data.accountNumber);
    formData.append("ifscCode", data.ifscCode);
    formData.append("panCardUrl", data.panCardUrl[0]);

    // Append course2Url only if it exists (since it's optional)
    if (data.course2Url?.[0]) {
      formData.append("course2Url", data.course2Url[0]);
    }

    dispatch(loader(true));
    dispatch(
      educatorRegisterCb(formData, (result) => {
        dispatch(loader(false));
        if (result.status) {
          toast.success(result.message);
          setFile("");
          reset();
          setPreviewImage("");
        } else {
          toast.error(result.message);
          dispatch(loader(false));
        }
      })
    );
  };

  const handleProfilePic = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      setFile(selectedFile);

      // Create a preview image URL
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setPreviewImage(
        "https://cdn.landesa.org/wp-content/uploads/default-user-image.png"
      );
      setFile(null);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(loader(true));
    dispatch(
      categoryList((resp) => {
        if (resp.status) {
          setCategoryListData(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  }, []);

  return (
    <div className="EDucaTorPage">
      <div className="PageHEaders edu">
        <div className="container">
          <div className="row">
            <div className="col-12"></div>
          </div>
        </div>
      </div>
      <div className="EduCatorFrom pb-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-10">
              <div className="PAgeTit small pt-3 pb-3">
                <h2>Educator Sign Up</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-10">
              <div className="eDUcAfo">
                <form onSubmit={handleSubmit(signUpFn)}>
                  <div className="ProFi1 ">
                    <label htmlFor="imgupload">
                      <input
                        type="file"
                        id="imgupload"
                        onChange={handleProfilePic}
                        style={{ display: "none" }}
                      />
                      <i>
                        <AiFillCamera />
                      </i>
                      <img
                        src={previewImage ? previewImage : Profimage}
                        className="img-fluid border"
                        style={{
                          width: "150px",
                        }}
                      />
                    </label>
                    {errors.photoUrl && (
                      <small style={{ color: "red", float: "left" }}>
                        {errors.photoUrl.message}
                      </small>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="EduCf">
                        <label>Name</label>
                        <input
                          type="text"
                          className="inputFormF namecl"
                          placeholder="Name"
                          name="name"
                          id="name"
                          {...register("name", {
                            required: "Username is required",
                            pattern: {
                              value: /^[A-Za-z\s]+$/, // Accepts letters and spaces
                              message:
                                "Invalid format. User name accepts only characters and spaces",
                            },
                          })}
                        />
                        {errors.name && (
                          <small style={{ color: "red", float: "left" }}>
                            {errors.name.message}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="EduCf">
                        <label>Email Address</label>
                        <input
                          type="text"
                          className="inputFormF"
                          placeholder="Email Address"
                          name="email"
                          id="email"
                          {...register("email", {
                            required: "Email is required",
                            pattern: {
                              value:
                                /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/,
                              message: "Invalid Format",
                            },
                          })}
                        />
                        {errors.email && (
                          <small style={{ color: "red", float: "left" }}>
                            {errors.email.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="EduCf">
                        <label>Phone Number</label>
                        <input
                          type="text"
                          className="inputFormF"
                          placeholder="Mobile Number"
                          name="phoneNumber"
                          id="phoneNumber"
                          {...register("phoneNumber", {
                            required: "Mobile Number is required",
                            pattern: {
                              value:
                                /^(?![0]{10}$)(?:\(\d{3}\)\s?|\d{3}[-.\s]?)?\d{3}[-.\s]?\d{4}$/,
                              message:
                                "Invalid Format.Please enter 10 digit Mobile number",
                            },
                          })}
                        />
                        {errors.phoneNumber && (
                          <small style={{ color: "red", float: "left" }}>
                            {errors.phoneNumber.message}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="EduCf">
                        <label>City</label>
                        <input
                          type="text"
                          className="inputFormF"
                          placeholder="City"
                          name="city"
                          id="city"
                          {...register("city", {
                            required: "city is required",
                            pattern: {
                              value: /^[A-Za-z]+$/,
                              message:
                                "Invalid format.city accept only characters",
                            },
                          })}
                        />
                        {errors.city && (
                          <small style={{ color: "red", float: "left" }}>
                            {errors.city.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="EduCf">
                        <label>Designation</label>
                        <input
                          type="text"
                          className="inputFormF"
                          placeholder="Designation"
                          name="Designation"
                          id="designation"
                          {...register("designation", {
                            required: "designation is required",
                            pattern: {
                              value: /^[A-Za-z\s]+$/,

                              message:
                                "Invalid format. Designation accept only characters",
                            },
                          })}
                        />
                        {errors.designation && (
                          <small style={{ color: "red", float: "left" }}>
                            {errors.designation.message}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="EduCf">
                        <label>Expertise</label>

                        <select
                          name="expertise"
                          {...register("expertise", {
                            required: "expertise is required",
                            pattern: {
                              value: /^(?!.*test).*$/,
                              message: "select one option",
                            },
                          })}
                        >
                          <option value="test">Select option</option>
                          {categoryListData.map((item) => (
                            <option key={item.id} value={item.name}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        {errors.expertise && (
                          <p style={{ color: "red" }}>
                            {" "}
                            {errors.expertise.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="EduCf dotted">
                        <label>Upload CV</label>
                        <Form.Group controlId="formFile" className="mb-3">
                          <Form.Control
                            placeholder="Choose"
                            type="file"
                            name="cvUrl"
                            {...register("cvUrl", {
                              required: "Resume required",
                              validate: {
                                isPDF: (file) => {
                                  const allowedFormats = ["application/pdf"];
                                  return (
                                    allowedFormats.includes(file[0]?.type) ||
                                    "Invalid file format. Please upload a PDF file."
                                  );
                                },
                                lessThan10MB: (files) =>
                                  files[0]?.size < 10485760 ||
                                  "File size should be Max 10MB", // 10MB in bytes (10 * 1024 * 1024)
                              },
                            })}
                          />
                          {errors.cvUrl && (
                            <p style={{ color: "red" }}>
                              {" "}
                              {errors.cvUrl.message}
                            </p>
                          )}
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="EduCf dotted">
                        <label>Upload Sample Course 1 (MP4 or PPT/PPTX)</label>
                        <Form.Group controlId="formFile" className="mb-3">
                          <Form.Control
                            placeholder="Choose"
                            type="file"
                            name="course1Url"
                            {...register("course1Url", {
                              required: "Sample course file is required",
                              validate: {
                                isValidFormat: (file) => {
                                  const allowedFormats = [
                                    "video/mp4",
                                    "application/vnd.ms-powerpoint",
                                    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                                  ];
                                  return (
                                    allowedFormats.includes(file[0]?.type) ||
                                    "Invalid file format. Please upload an MP4 or PPT/PPTX file"
                                  );
                                },
                                lessThan50MB: (files) =>
                                  files[0]?.size < 52428800 ||
                                  "File size should be Max 50MB",
                              },
                            })}
                          />
                          {errors.course1Url && (
                            <p style={{ color: "red" }}>
                              {errors.course1Url.message}
                            </p>
                          )}
                        </Form.Group>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="EduCf dotted">
                        <label>
                          Upload Sample Course 2 (MP4 or PPT/PPTX) - Optional
                        </label>
                        <Form.Group controlId="formFile" className="mb-3">
                          <Form.Control
                            placeholder="Choose"
                            type="file"
                            name="course2Url"
                            {...register("course2Url", {
                              required: false, // Made optional
                              validate: {
                                isValidFormat: (file) => {
                                  if (!file || !file[0]) return true; // Skip validation if no file
                                  const allowedFormats = [
                                    "video/mp4",
                                    "application/vnd.ms-powerpoint",
                                    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                                  ];
                                  return (
                                    allowedFormats.includes(file[0]?.type) ||
                                    "Invalid file format. Please upload an MP4 or PPT/PPTX file"
                                  );
                                },
                                lessThan50MB: (files) => {
                                  if (!files || !files[0]) return true; // Skip validation if no file
                                  return (
                                    files[0]?.size < 52428800 ||
                                    "File size should be Max 50MB"
                                  );
                                },
                              },
                            })}
                          />
                          {errors.course2Url && (
                            <p style={{ color: "red" }}>
                              {errors.course2Url.message}
                            </p>
                          )}
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="EduCf">
                        <label>Short Description</label>
                        <textarea
                          className="inputFormF txt"
                          placeholder="Description"
                          name="description"
                          id="description"
                          {...register("description", {
                            required: "Description is required",
                            pattern: {
                              value: /.*$/,
                              message:
                                "Invalid format. Description should contain only letters, numbers, and spaces",
                            },
                          })}
                        />
                        {errors.description && (
                          <small className="error-message">
                            {errors.description.message}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="EduCf">
                        <label>Full Name (as per bank account)</label>
                        <input
                          type="text"
                          className="inputFormF"
                          placeholder="Full Name"
                          {...register("fullName", {
                            required: "Full Name is required",
                            pattern: {
                              value: /^[A-Za-z\s]+$/,
                              message:
                                "Full Name should contain only letters and spaces",
                            },
                          })}
                        />
                        {errors.fullName && (
                          <small className="error-message">
                            {errors.fullName.message}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="EduCf">
                        <label>Account Number</label>
                        <input
                          type="text"
                          className="inputFormF"
                          placeholder="Account Number"
                          {...register("accountNumber", {
                            required: "Account Number is required",
                            pattern: {
                              value: /^\d{9,18}$/,
                              message: "Please enter a valid account number",
                            },
                          })}
                        />
                        {errors.accountNumber && (
                          <small className="error-message">
                            {errors.accountNumber.message}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="EduCf">
                        <label>IFSC Code</label>
                        <input
                          type="text"
                          className="inputFormF"
                          placeholder="IFSC Code"
                          {...register("ifscCode", {
                            required: "IFSC Code is required",
                            pattern: {
                              value: /^[A-Z]{4}0[A-Z0-9]{6}$/,
                              message: "Please enter a valid IFSC code",
                            },
                          })}
                        />
                        {errors.ifscCode && (
                          <small className="error-message">
                            {errors.ifscCode.message}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="EduCf dotted">
                        <label>Upload PAN Card</label>
                        <Form.Group controlId="formFile" className="mb-3">
                          <Form.Control
                            type="file"
                            name="panCardUrl"
                            {...register("panCardUrl", {
                              required: "PAN Card is required",
                              validate: {
                                lessThan2MB: (files) =>
                                  files[0]?.size < 2097152 ||
                                  "File size should be less than 2MB",
                              },
                            })}
                          />
                          {errors.panCardUrl && (
                            <small className="error-message">
                              {errors.panCardUrl.message}
                            </small>
                          )}
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      <input
                        type="checkbox"
                        className="form-check-input"
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: "10px",
                        }}
                        id="termsCheck"
                        {...register("termsAccepted", {
                          required: "You must accept the terms and conditions",
                        })}
                      />
                      <label className="form-check-label" htmlFor="termsCheck">
                        I accept the{" "}
                        <Link to="/termsncondt" target="_blank">
                          terms and conditions
                        </Link>
                      </label>
                      {errors.termsAccepted && (
                        <small
                          className="error-message d-block"
                          style={{ color: "red" }}
                        >
                          {errors.termsAccepted.message}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-12">
                      <div className="SIGBtn">
                        <div className="EduCf">
                          <button type="submit">Sign Up</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Educator;
